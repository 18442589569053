import { toast } from 'react-toastify';
// import React, { FC, useEffect, useState } from 'react';
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Link, useHistory, withRouter } from 'react-router-dom';
// import { API } from '../api';
import Logo  from '../components/Logo';
import InputField from '../components/InputField';
import Button from '../components/Button';
import axios from 'axios'
import {setAuthToken} from '../helpers/setAuthToken'

function Login() {


  const history = useHistory()
  if (localStorage.getItem('rest_id') !== null && localStorage.getItem('jwt') !== null) {
    // setAuthToken(localStorage.getItem('jwt'));
    // console.log(axios.defaults.headers)
    history.push('/dashboard');
    // window.location.reload();
  }

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    console.log(localStorage.getItem('rest_id'), localStorage.getItem('jwt'))
    console.log((localStorage.getItem('rest_id') === undefined || localStorage.getItem('jwt') === undefined))

    const baseURL = process.env.REACT_APP_API_URL
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;
    await axios.post(baseURL+'/restaurant/api/login/', { username: username, password:password })
      .then((response) => {
        if (response.data.data.data) { // @ts-ignore
          localStorage.setItem('jwt', response.data.data.data.token);
          localStorage.setItem(
            'rest_id',
            response.data.data.data.restaurant.id,
          );
          localStorage.setItem('toPrint', JSON.stringify([]));
          setAuthToken(response.data.data.data.token);

          // ${response.data.data.restaurant.id}
          // window.location.href = '/admin/dashboard';
          history.push('/dashboard');

          // window.location.reload();
          // console.log("ok")
        }

      })
      .catch((error) => {
        console.log("in catch")
        toast.error('Email or Password is wrong', { containerId: 'B' });
      });

  };

  return (
    <>
    <form onSubmit={(event) => handleFormSubmission(event)}>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
            <Logo infoText="Log in" mutedText={false} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="email" name="username" placeholder="E-mail" />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField
              type="password"
              name="password"
              placeholder="Wachtwoord"
            />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mb-2 text-right">
            <Link to="/forgotPassword">
              <span className="text-muted small">Forgot Password?</span>
            </Link>
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-2">
            <Button
              htmlType="submit"
              name="Log in"
              dangerButton={true}
              btnLongWidth={true}
              Link={true}
            />
          </div>
        </div>
      </div>
    </form>
    </>
  );
}
export default Login;

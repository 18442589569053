import React, {useEffect,useState} from "react";
import ReactDOM from "react-dom/client";
import { toast, ToastContainer } from 'react-toastify';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import HomeLayout from "layouts/Home.js";

import RouteGuard from "./components/RouteGuard"
import Routers from './routes'

import {setAuthToken} from './helpers/setAuthToken'
import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import Dashboard from "./views/Dashboard";

import runOneSignal from "./onesignal";
// import OneSignal from 'react-onesignal';



// async function runOneSignal() {
//   console.log("runONeSignal")
//   await OneSignal.init({ appId: '879730b6-73d1-4bee-81c6-cf60362467cf', allowLocalhostAsSecureOrigin: true});
//   OneSignal.showSlidedownPrompt();
//
// }
function App() {

    // window.OneSignal = window.OneSignal || [];
    // const OneSignal = window.OneSignal;
    useEffect(() => {
        async function runOneSignal() {
          window.OneSignal = window.OneSignal || [];
          const OneSignal = window.OneSignal;
          await OneSignal.init({ appId: '879730b6-73d1-4bee-81c6-cf60362467cf', allowLocalhostAsSecureOrigin: true});
          OneSignal.showSlidedownPrompt();

          // OneSignal.push(function() {
          // OneSignal.on('notificationDisplay', function(event) {
          //   alert(event)
          // })
          console.log('runOneSignal()')
        }
        runOneSignal()
    })


  //check jwt token
  const token = localStorage.getItem("token");
  // const [initialized, setInitialized] = useState(false);


  if (token) {
      setAuthToken(token);
  }

  // if (initialized){
  //     OneSignal.init({ appId: '879730b6-73d1-4bee-81c6-cf60362467cf' }).then(() => {
  //         setInitialized(true);
  //         OneSignal.showSlidedownPrompt().then(() => {
  //           console.log("Onesignal.showSlidedownPrompt")
  //         });
  //       })
  // }


  // OneSignal.init({ appId: '879730b6-73d1-4bee-81c6-cf60362467cf' });

  // useEffect(() => {
  //
  //   runOneSignal();
  // })

  return (
    <div className="App">

      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/login" component={Login} />
            <Route exact={true} path="/forgotPassword" component={ForgotPassword} />
            <RouteGuard exact={true} path="/dashboard" component={Dashboard}/>
          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
import React from 'react';
import './style.css';

const InputField = (props) => {
  if (props.type === "textarea"){
    return (
    <textarea
      // type={props.type}
      className="form-control"
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      // max={props.max}
      // min={props.min}
      required={props.required}
      minLength={props.minlength}
      disabled={props.disabled}
    />
  );
  }
  else{
    return (
    <input
      type={props.type}
      className="form-control"
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      max={props.max}
      min={props.min}
      required={props.required}
      minLength={props.minlength}
      disabled={props.disabled}
    />
  );
  }

}

export default InputField;

import React, {useState, useEffect} from 'react';
import './style.css';
import {Button, Card, Col, Row} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import InputField from "./InputField";

import querystring from "querystring-es3";
import SetupPrintService from '../assets/SetupPrintService.zip';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'

const Settings = () => {
    // console.log(props)

    const baseURL = process.env.REACT_APP_API_URL;
    const [rest_id, setRest_id] = useState(localStorage.getItem('rest_id'))
    const [restStatus, setRestStatus] = useState('Offline')
    const [defaultMinutes, setDefaultMinutes] = useState(0)
    const [customerMessage, setCustomerMessage] = useState("")
    const [checked, setChecked] = useState(false);

    const [autoPrintStatus, setAutoPrintStatus] = useState(false)


    async function getRestData() {
        // console.log(order_id, "in orderinfo")
        const baseURL = process.env.REACT_APP_API_URL

        await axios.get(baseURL + '/restaurant/' + rest_id.toString())

            .then((response) => {

                setRestStatus(response.data.data[0]['Availability'])
                setDefaultMinutes(response.data.data[0]['defaultMinutes'])
                setCustomerMessage(response.data.data[0]['customer_msg'])
                if (response.data.data[0]['Availability'] === "Online" || response.data.data[0]['Availability'] === "ONLINE"){
                    setChecked(true)
                }
                else{
                    setChecked(false)
                }
                setAutoPrintStatus(response.data.data[0]['autoPrint'])
                // console.log(response.data.data.data.token);
                // let info =  response.data.data.orders[0]

            })
            .catch((error) => {
                console.log("in catch", error)
                toast.error('Unable to receive orders', {containerId: 'B'});
            });
    }
    async function updateDefaultMessage(event) {
        event.preventDefault();

        const customer_message = customerMessage;
        const time_in_minutes = defaultMinutes;
        const baseURL = process.env.REACT_APP_API_URL;
        await axios.post(baseURL+'/restaurant/ChangecustomerMsg/'+rest_id, querystring.stringify({customer_message: customer_message, time_in_minutes: parseInt(time_in_minutes)}))

        .then((response) => {

            console.log("updateDefaultMessage")
            console.log(response)
            // console.log(response.data.data.data.token);
            // let info =  response.data.data.orders[0]

        })
        .catch((error) => {
            console.log("in catch", error)
            toast.error('Unable to receive orders', { containerId: 'B' });
        });
    }
    async function statusChange() {

        const baseURL = process.env.REACT_APP_API_URL;


        // var data = new FormData(); // 2
        //
        let restaurantStatus = 'Online'
        if (restStatus === 'Online' || restStatus === "ONLINE"){
            restaurantStatus = 'Offline'
        }
            // console.log("if")
            // data.append("restaurantStatus", 'OFFLINE')

        // else{
        //     console.log("else")
        //     data.append("restaurantStatus", 'ONLINE')
        // }
        // console.log(data)
        await axios.post(baseURL+'/restaurant/ChangeRestaurantStatus/'+rest_id, querystring.stringify({restaurantStatus: restaurantStatus}))

        .then((response) => {
            console.log("statusChange")
            console.log(response)
            setRestStatus(restaurantStatus)

            if (restaurantStatus === "Online" || restaurantStatus === "ONLINE"){
                setChecked(true)
            }
            else{
                setChecked(false)
            }
            // console.log(response.data.data.data.token);
            // let info =  response.data.data.orders[0]

        })
        .catch((error) => {
            console.log("in catch", error)
            toast.error('Unable to change status', { containerId: 'B' });
        });
    }

    async function autoPrintChange() {

        const baseURL = process.env.REACT_APP_API_URL;

        await axios.post(baseURL+'/restaurant/ChangeRestaurantAutoPrint/'+rest_id, {autoPrint: !autoPrintStatus})

        .then((response) => {
            console.log(response)
            if (response.status === 200){
                setAutoPrintStatus(!autoPrintStatus)
            }
        })
        .catch((error) => {
            console.log("in catch", error)
            toast.error('Unable to change status of autoPrint', { containerId: 'B' });
        });
    }


    async function print_order() {
        console.log("print")

    }

        useEffect(() => {
  // run something every time name changes
      getRestData();
    }, []);
    return (
        <>
            <DeviceOrientation>
                <Orientation orientation='landscape' alwaysRender={false}>
            <Card className="card-stats orderCard" style={{width:'50vw'}}>
              <Card.Body>

                <Row xs="2" lg="2">
                  <Col xs="5" lg="4">
                      Status
                    </Col>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                        {restStatus}
                        <label className="switch">

                            <input type="checkbox" checked={checked} onChange={() => statusChange()}/>
                                <span className="slider round"></span>
                        </label>
                    </div>
                </Row>
                  <Row xs="2" lg="2">
                  <Col xs="5" lg="4">
                      Print Automatisch
                    </Col>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                        <label className="switch">

                            <input type="checkbox" checked={autoPrintStatus} onChange={() => autoPrintChange()}/>
                                <span className="slider round"></span>
                        </label>
                    </div>
                </Row>
                  <Row xs="2" lg="2">
                  <Col xs="5" lg="4">
                      Printer setup
                    </Col>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">

                        <Button><a href={SetupPrintService} download="HappyOrderPrinterSetup.zip">Download</a></Button>
                    </div>
                </Row>
                  </Card.Body>
            </Card>
        <Card className="card-stats orderCard" style={{width:'50vw'}}>
              <Card.Body>
                <form onSubmit={(event) => updateDefaultMessage(event)}>
                <Row xs="2" lg="2">

                  <Col xs="5" lg="4">
                      <p>Bereidingstijd in min</p>
                  </Col>

                      <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="number" name="time_in_minutes" placeholder="Bereidingstijd in min" value={defaultMinutes} onChange={(event) => setDefaultMinutes((defaultMinutes) => event.target.value)} />
          </div>


                </Row>

                  <Row xs="2" lg="2">

                  <Col xs="5" lg="4">
                      <p>Bericht indien gesloten</p>
                  </Col>

                      <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="textarea" name="customer_message" placeholder="Bericht indien gesloten" value={customerMessage} onChange={(event) => setCustomerMessage((customerMessage) => event.target.value)} />
          </div>


                </Row>



                      <Button style={{width:'50%', align:'center'}} className="btn btn-success text-center" type="submit">Save</Button>


                </form>

              </Card.Body>

            </Card>
                </Orientation>
                <Orientation orientation='portrait' alwaysRender={false}>
            <Card className="card-stats orderCard" style={{width:'90vw'}}>
              <Card.Body>

                <Row xs="2" lg="2">
                  <Col xs="5" lg="4">
                      Status
                    </Col>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
                        {restStatus}
                        <label className="switch">

                            <input type="checkbox" checked={checked} onChange={() => statusChange()}/>
                                <span className="slider round"></span>
                        </label>
                    </div>
                </Row>
                  <Row xs="2" lg="2">
                  <Col xs="5" lg="4">
                      Printer setup
                    </Col>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">

                        <Button><a href={SetupPrintService} download="HappyOrderPrinterSetup.zip">Download</a></Button>
                    </div>
                </Row>
                  </Card.Body>
            </Card>
        <Card className="card-stats orderCard" style={{width:'90vw'}}>
              <Card.Body>
                <form onSubmit={(event) => updateDefaultMessage(event)}>
                <Row xs="2" lg="2">

                  <Col xs="5" lg="4">
                      <p>Bereidingstijd in min</p>
                  </Col>

                      <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="number" name="time_in_minutes" placeholder="Bereidingstijd in min" value={defaultMinutes} onChange={(event) => setDefaultMinutes((defaultMinutes) => event.target.value)} />
          </div>


                </Row>

                  <Row xs="2" lg="2">

                  <Col xs="5" lg="4">
                      <p>Bericht indien gesloten</p>
                  </Col>

                      <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="textarea" name="customer_message" placeholder="Bericht indien gesloten" value={customerMessage} onChange={(event) => setCustomerMessage((customerMessage) => event.target.value)} />
          </div>


                </Row>



                      <Button style={{width:'90%', align:'center'}} className="btn btn-success text-center" type="submit">Save</Button>


                </form>

              </Card.Body>

            </Card>
                </Orientation>
            </DeviceOrientation>
</>
      );
}

export default Settings;




import axios from 'axios';

export const setAuthToken = (token) => {

   if (token) {
       console.log("Set TOKEN")
       axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
       // axios.defaults.mode = "cors";
   }
   else {
       console.log("Delete TOKEN")
       delete axios.defaults.headers.common["Authorization"];
   }
}
import React,{useEffect, useState}  from 'react';
import './style.css';
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import axios from "axios";
// import OrderCard from "./OrderCard";
import {toast} from "react-toastify";
import {
    BsFillGeoAltFill,
    BsFillCalendarCheckFill,
    BsFillTelephoneFill,
    BsFillEnvelopeFill,
    BsPersonFill,
    BsFillPersonFill,
    BsFillXCircleFill,
    BsFillCheckCircleFill

} from "react-icons/bs";


function OrderInfo (props) {

  const rest_id = props.rest_id
  const order_id = props.order_id
  const [orderInfo, setOrderInfo] = useState({})
  const [articles, setArticles] = useState([])

  async function getOrderInfo() {
      // console.log(order_id, "in orderinfo")
    const baseURL = process.env.REACT_APP_API_URL

    await axios.get(baseURL+'/restaurant/'+rest_id+'/order/'+order_id)

      .then((response) => {
        // console.log("orderinfo")
        // console.log(response)
        // console.log(response.data.data.data.token);
        let info =  response.data.data.orders[0]
          // console.log(info)
        let datetime = new Date(info.delivery_time)
        info.delivery_time = datetime.toLocaleTimeString('nl', {
                          hour: '2-digit',
                          minute: '2-digit',
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',

                })
            // console.log("info:",info)
          // if (info.tableid !== -1){
          //     info.service_cost = 0.00
          // }
          // else if (info.restaurant.service_cost_type === "fixed_amount"){
          //     info.service_cost = parseFloat(info.restaurant.service_cost_fixed_amount)
          // }
          // else{
          //     info.service_cost = parseFloat(info.total_amount)*parseFloat(info.restaurant.service_cost_percentage)/100
          // }
          // if (info.order_type === 'Pickup' || info.tableid !== -1){
          //     info.delivery_cost=0.00
          // }
          // else {
          //     info.delivery_cost = parseFloat(info.restaurant.delivery_cost)
          // }
          // console.log(info.total_amount,info.delivery_cost,info.total_amount+info.delivery_cost)

          if (info.tableid !== -1){

              info.order_type = "Tafel " + info.tableid
          }

        setOrderInfo(info)
        console.log('orderInfo',info)
        let art = {}
        for (let i = 0; i<response.data.data.Articles.length; i++){
            if (art[response.data.data.Articles[i].article.category.name]){
                art[response.data.data.Articles[i].article.category.name].push(response.data.data.Articles[i])
            }else{
                art[response.data.data.Articles[i].article.category.name] = [response.data.data.Articles[i]]
            }
        }
        // console.log(art)
        let artHtml=[]
        let id=0
        for (const [key, value] of Object.entries(art)){
            artHtml.push(<tr key={id}>
                  <th>{key}</th>
                  <td></td>
                </tr>)
            id = id+1
            for (let j = 0; j < value.length; j++){
                artHtml.push(<tr key={id}>
                  <td>{value[j].article_quantity} X {value[j].article.articlename}</td>
                  <th>€{(value[j].article.price * value[j].article_quantity)?.toFixed(2) ?? 0.0}</th>
                </tr>)
                id = id+1
                for (let k = 0; k < value[j].article_options.length; k++){
                // console.log(value[j].article_options[k].article_option.choice)
                artHtml.push(<tr key={id}>
                  <td style={{color:"grey"}}>{value[j].article_options[k].article_option_quantity !== 0 && value[j].article_options[k].article_option_quantity}{value[j].article_options[k].article_option.choice} {value[j].article_options[k].article_option.optionname.optionname}</td>
                  {/*<td style={{color:"grey"}}>{value[j].article_options[k].article_option.choice}</td>*/}
                    <th>€{(value[j].article_options[k].choice_price * value[j].article_quantity)?.toFixed(2) ?? 0.0}</th>
                </tr>)
                id = id+1
                }
            }
        }
        setArticles(artHtml)
      })
      .catch((error) => {
        console.log("in catch")
        toast.error('Unable to get order info', { containerId: 'B' });
      });
  }
    useEffect(() => {
  // run something every time name changes
  getOrderInfo()

}, [order_id, rest_id]);

  // let datetime = new Date()
  return (
      <Card className="card-stats">
          <Card.Body>

              <Row xs="3" lg="3">
                  <Col xs="5" lg="4">
                      <BsFillCalendarCheckFill/> {orderInfo.delivery_time}
                  </Col>
                  <Col xs="5" lg="4">
                      <BsFillTelephoneFill/> {orderInfo.phoneNumber}
                  </Col>
                  <Col xs="5" lg="4">
                      <BsFillGeoAltFill/> {orderInfo.address}
                  </Col>
              </Row>
              <Row xs="3" lg="3">
                  <Col xs="5" lg="4">
                      <BsFillPersonFill/> {orderInfo.customer_name}
                  </Col>
                  <Col xs="5" lg="4">
                      <BsFillEnvelopeFill/> {orderInfo.email}
                  </Col>

              </Row>
              <Row xs="3" lg="3">
                  <Col xs="5" lg="4">
                      Type: {orderInfo.order_type}
                  </Col>
                  <Col xs="5" lg="4">
                      Betaald: {orderInfo.payment_status? <BsFillCheckCircleFill/>:<BsFillXCircleFill/>}
                  </Col>

              </Row>
              <br/>
              <Table>
              <tbody>
                <tr key={-1}>
                  <th style={{backgroundColor:"lightgrey"}}>Bestelling</th>
                  <td></td>
                </tr>

              {articles}
              <tr key={-2}><td></td><td></td></tr>
                <tr key={-3} style={{backgroundColor:"lightgrey"}}>
                  <td>Subtotaal</td>
                  <th style={{color:"red"}}>€{(parseFloat(orderInfo.total_amount) - parseFloat(orderInfo.service_cost) - parseFloat(orderInfo.delivery_cost))?.toFixed(2) ?? 0.0}</th>
                </tr>
                <tr key={-4} style={{backgroundColor:"lightgrey"}}>
                  <td>Servicekosten</td>
                  <th style={{color:"red"}}>€{parseFloat(orderInfo.service_cost)?.toFixed(2) ?? 0.0}</th>
                </tr>
              <tr key={-5} style={{backgroundColor:"lightgrey"}}>
                  <td>Bezorgkosten</td>
                  <th style={{color:"red"}}>€{parseFloat(orderInfo.delivery_cost)?.toFixed(2) ?? 0.0}</th>
                </tr>

              <tr key={-6} style={{backgroundColor:"lightgrey"}}>
                  <td>Totaal</td>
                  <th style={{color:"red"}}>€{(parseFloat(orderInfo.total_amount))?.toFixed(2) ?? 0.0}</th>
                </tr>
              </tbody>
              </Table>
          </Card.Body>

      </Card>

  )
}

export default OrderInfo;




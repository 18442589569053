import React, {useEffect,useState} from "react";

// import {discover, print} from "react-native-epson-printer";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import OrderCard from '../components/OrderCard';
import OrderInfo from "../components/OrderInfo";
import Settings from "../components/Settings";
import DeleteConfirmation from "../components/DeleteConfirmation";
import axios from "axios";
//import {setAuthToken} from "../helpers/setAuthToken";
import {toast} from "react-toastify";
import AdminNavbar from "../components/Navbars/AdminNavbar";

function Dashboard () {
  // const socket = io("http://127.0.0.0.1:13000");
   // connects to server address
  // socket.on('connect', () => {
  //     console.log('connected to server');
  // });
  // constructor(props) {
  //   super(props);
  //   this.state = {cards: [], status: "Pending"}
  //   this.updateOrders()
  // }
  const [cards, setCards] = useState([])
  const [status, setStatus] = useState('Pending')

  const [rest_id, setRest_id] = useState(localStorage.getItem('rest_id'))
  const [order_id, setOrder_id] = useState(-1)

  const [highlighted_order_id, setHighlighted_order_id] = useState(-1)

  const [printed, setPrinted] = useState([])

  const [restName, setRestName] = useState('')

  const [toDate, setToDate] = useState(new Date())

  const baseURL = process.env.REACT_APP_API_URL

  const OneSignal = window.OneSignal;
    useEffect(() => {
        OneSignal.push(function() {

            OneSignal.getExternalUserId().then(function(externalUserId){
                // console.log('externalUserId',externalUserId)
                if (externalUserId!==rest_id){
                    OneSignal.setExternalUserId(rest_id);
                    // console.log('OneSignal.setExternalUserId(', rest_id)
                }
              });
        });
        async function getRestName(){

            await axios.get(baseURL+'/SiteApi/fetchallrestaurant/').then((response) => {
                const restaurantInfo = response.data.data.filter(
                    (r) => r.id === parseInt(rest_id),
                );
                console.log(restaurantInfo)
                if (restaurantInfo.length > 0) {
                    setRestName(restaurantInfo[0].RestaurantName);
                }
            });
        }
        getRestName()
    }, []);

    useEffect(() => {
        OneSignal.on('notificationDisplay', function(event) {
            console.warn('OneSignal notification displayed:', event);
  });
    });

  async function updateOrders(setSelectedOrder=true) {
      // console.log("in updateOrders")
      // console.log(status, rest_id, order_id)

    // console.log(localStorage.getItem('rest_id'), localStorage.getItem('jwt'))
    // console.log((localStorage.getItem('rest_id') === undefined || localStorage.getItem('jwt') === undefined))



    // const username = event.target.elements.username.value;
    // const password = event.target.elements.password.value;
    // console.log(axios.defaults.headers)
    let today = new Date().toISOString().slice(0, 10);
      // console.log('today = ',today)

    let to = toDate.toISOString().slice(0, 10);
    // console.log(today)
      // await axios.get(baseURL+'/restaurant/allorders/'+rest_id,{params:{order_status: order_status}})
    await axios.get(baseURL+'/restaurant/allorderswithdate/'+rest_id,{params:{order_status: status, from: today, to:to}})

      .then((response) => {
        let orders = []
        if (status !== "Pending"){
            orders = response.data.results.slice().reverse();
        }
        else{
            orders = response.data.results;
        }
        // console.log(response)
        // console.log(response.data.data.data.token);
        let temp_cards = []
        for (let i=orders.length-1; i >= 0; i--){

          // console.log(i)
          let orderType = ""
          if (orders[i].tableid !== -1){

              orderType = "Tafel " + orders[i].tableid
          }
          else{

              orderType = orders[i].order_type
          }

          temp_cards.push(<OrderCard onClick={() => setOrder_id((order_id) => orders[i].order_id)} update_orders={updateOrders} status={status} tableid={orders[i].tableid} id={orders[i].order_id} key={orders[i].order_id} order_id={orders[i].order_id} daily_order_number={orders[i].daily_order_number} customer_name={orders[i].customer_name} total_amount={orders[i].total_amount} delivery_time={orders[i].delivery_time} order_type={orderType} user_note={orders[i].user_note} printed={printed} setPrinted={setPrinted} />)

        }

        setCards(temp_cards)

        if(temp_cards.length === 0){

            setOrder_id((order_id) => -1)
        }
        else{
            if(order_id === -1){

                setOrder_id((order_id) => orders[orders.length - 1].order_id)
            }
        }
        if (setSelectedOrder && temp_cards.length !== 0){

            setOrder_id((order_id) => orders[orders.length - 1].order_id)
        }

        // cards = orders
        // console.log(cards)

      })
      .catch((error) => {
        console.log("in catch")
        toast.error('Unable to receive orders', { containerId: 'B' });
      });

  }

  // updateOrdersOnEvent = async (event) => {
  //   event.preventDefault();
  //   await this.updateOrders()
  // }
    useEffect(() => {
      const interval = setInterval(() => {
            updateOrders(false)
      }, 10000);
      return () => clearInterval(interval);
  }, [order_id, status, toDate]);

    useEffect(() => {
  // run something every time name changes
  updateOrders()
}, [status, toDate]);

    useEffect(() => {
        // console.log(highlighted_order_id)

        if (order_id !== -1){
            if (highlighted_order_id !== -1){
                try {
                    document.getElementById(highlighted_order_id.toString()).style.background = "white"
                }
                catch (error){
                    setHighlighted_order_id(-1)
                }

            }
            document.getElementById(order_id.toString()).style.background = "lightgrey"
            setHighlighted_order_id(order_id)
        }


}, [order_id]);


    // useEffect(() => {
  // run something every time name changes
  //       console.log('printed changed', printed)
  //   updateOrders(false)
// }, printed);

//     useEffect(() => {
//   // run something every time name changes
//   console.log("printed changed", printed)
// }, [printed]);
  return (
      <>
        <AdminNavbar status={status} setStatus={setStatus} updateOrders={updateOrders} restName = {restName} setToDate = {setToDate} />
        <Container fluid>
            {status !== 'Settings' &&
                <DeviceOrientation>
                    <Orientation orientation='landscape' alwaysRender={false}>
                        <Row>
                            <Col lg="3" sm="6" style={{height: "90vh", overflow: "auto"}}>

                                {cards}
                            </Col>
                            <Col lg="15" sm="6" style={{height: "90vh", overflow: "auto"}}>
                                {order_id !== -1 &&
                                    <OrderInfo rest_id={rest_id} order_id={order_id}/>
                                }
                                {order_id === -1 &&
                                    <p>Geen bestellingen gevonden</p>
                                }

                            </Col>

                        </Row>
                    </Orientation>
                    <Orientation orientation='portrait' alwaysRender={false}>
                        {/*<Row style={{width: "100vw", overflowX: "scroll"}}>*/}
                            <div className='container-mobile'>
                                {cards}
                            </div>
                        {/*</Row>*/}
                        <Row style={{width: "100vw", overflow: "auto"}}>
                                {order_id !== -1 &&
                                    <OrderInfo rest_id={rest_id} order_id={order_id}/>
                                }
                                {order_id === -1 &&
                                    <p>Geen bestellingen gevonden</p>
                                }
                        </Row>
                    </Orientation>
                </DeviceOrientation>
            }
            {status === 'Settings' &&
                <center>
                    <Settings/>
                    {/*<ThermalPrinter/>*/}
                </center>

            }


        </Container>

      </>
  );
}

export default Dashboard;
import React from 'react';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';

const DeleteConfirmation = ({ showModal, hideModal, confirmModal, id, message }) => {
  return (
    <div className="text-center mt-5">
      <Modal isOpen={showModal} toggle={hideModal}>
        <ModalHeader>
          Bevestig verwijderen
        </ModalHeader>
        <ModalBody>
          <div className="alert alert-danger">{message}</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={hideModal}>
            Nee
          </Button>
          <Button color="danger" onClick={() => confirmModal(id)}>
            Ja
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteConfirmation;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AdminLayout from "../layouts/Admin";
import {setAuthToken} from "../helpers/setAuthToken";

const RouteGuard = ({ component: Component, path: path , exact: key}) => {

   function hasJWT() {
       let flag = false;
       //check user has JWT token
       localStorage.getItem("jwt") ? flag=true : flag=false

       if (flag){
           setAuthToken(localStorage.getItem('jwt'))
       }
       return flag
   }

   return (
       <Route path={path}
           render={props => (

               hasJWT() ?
                   <Component {...props} />
                   :
                   <Redirect to={{ pathname: '/login' }} />
           )}
           key={key}
       />
   );
};

export default RouteGuard;
import React, { Component, useState, useEffect} from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import routes from "routes.js";
import '../style.css';
import {setAuthToken} from "../../helpers/setAuthToken";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import nl from 'date-fns/locale/nl';

function Header(props) {
  const history = useHistory()
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
          [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
          onWindowResize = () => {
            clearTimeout(window.resizeLag)
            window.resizeLag = setTimeout(() => {
              delete window.resizeLag
              setOrientation(isLandscape() ? 'landscape' : 'portrait')
            }, 200)
          }

    useEffect(() => (
      onWindowResize(),
      window.addEventListener('resize', onWindowResize),
      () => window.removeEventListener('resize', onWindowResize)
    ),[])

  const togglecollapse = () => {
    console.log("toggle")
    if (orientation === 'landscape'){setOrientation('portrait')}
    else {setOrientation('landscape')}
  }
  const logout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('rest_id');
    setAuthToken(null);

    history.push('/login');

  }

  const update = (e) => {

    console.log(e)
    props.setStatus(e)
    // history.push(`/dashboard`);
    // props.updateOrders()
    // props.dashboard.updateOrders()
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.setToDate(date);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <button
            className="navbar-toggler hidden-lg-up"
            type="button"
            onClick={togglecollapse}
            // data-toggle="collapse"
            data-target="#mainNavbarCollapse"
          >
            &#9776;
          </button>
        <h2 style={{ marginRight: '10px' }}>{props.restName}</h2>
        <span>Bekijk orders t/m: <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        minDate={new Date()} // Set the minimum date to today
        placeholderText="Select a date"
        dateFormat="dd/MM/yyyy" // Set the date format
        locale={nl}
      /></span>

        <Collapse in={orientation === 'landscape'}>

          <div>
            {/*<div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">*/}
            <Nav navbar>
          {props.status === "Pending" &&
          <Navbar.Brand
            // href="#home"
            onClick={() => update("Pending")}
            className="mr-2"
          >
            Bestellingen in wacht
          </Navbar.Brand>
        }
        {props.status !== "Pending" &&
          <Navbar.Text
            // href="#home"
            onClick={() => update("Pending")}
            className="mr-2"
          >
            Bestellingen in wacht
          </Navbar.Text>
        }

          {props.status === "Accepted" &&
              <Navbar.Brand
                  // href="#home"
                  onClick={() => update("Accepted")}
                  className="mr-2"
              >
                Geaccepteerd
              </Navbar.Brand>
          }
          {props.status !== "Accepted" &&
              <Navbar.Text
                  // href="#home"
                  onClick={() => update("Accepted")}
                  className="mr-2"
              >
                Geaccepteerd
              </Navbar.Text>
          }
          {/*{props.status === "Rejected" &&*/}

          {/*    <Navbar.Brand*/}
          {/*        // href="#home"*/}
          {/*        onClick={() => update("Rejected")}*/}
          {/*        className="mr-2"*/}
          {/*    >*/}
          {/*      Geannuleerd*/}
          {/*    </Navbar.Brand>*/}
          {/*}*/}
          {/*{props.status !== "Rejected" &&*/}

          {/*    <Navbar.Text*/}
          {/*        // href="#home"*/}
          {/*        onClick={() => update("Rejected")}*/}
          {/*        className="mr-2"*/}

          {/*    >*/}
          {/*      Geannuleerd*/}
          {/*    </Navbar.Text>*/}
          {/*}*/}
        {/*</div>*/}
        {/*    </Nav>*/}
        {/*  <Nav className="ml-auto" navbar>*/}
              <div style={{float: "right", paddingLeft: "100px"}}>
            {props.status === "Settings" &&
          <Navbar.Brand
            // href="#home"
            onClick={() => update("Settings")}
            className="mr-2"
          >
            Instellingen
          </Navbar.Brand>
        }
        {props.status !== "Settings" &&
          <Navbar.Text
            // href="#home"
            onClick={() => update("Settings")}
            className="mr-2"
          >
            Instellingen
          </Navbar.Text>
        }
        <Navbar.Text
            // href="#home"
            onClick={() => logout()}
            className="mr-2"
          >
            Logout
          </Navbar.Text>
              </div>
          </Nav>
        </div>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;